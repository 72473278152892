import React from "react";

// packages
import Immutable from "immutable";
import linkyfyIt from "linkify-it";
import tlds from "tlds";

// draft-js-core
import Editor, { composeDecorators } from "draft-js-plugins-editor";
import { DefaultDraftBlockRenderMap, convertToRaw } from "draft-js";

// draft-js plugins

import createUndoPlugin from "draft-js-undo-plugin";
import createImagePlugin from "draft-js-image-plugin";
import createFocusPlugin from "draft-js-focus-plugin";
import createAnchorPlugin from "draft-js-anchor-plugin";
import createSideToolbar from "draft-js-side-toolbar-plugin";
import createAlignmentPlugin from "draft-js-alignment-plugin";
import createResizablePlugin from "draft-js-resizeable-plugin";
import createLinkfyPlugin from "draft-js-linkify-plugin";

import createVideoPlugin from "draft-js-video-plugin";
import createStaticToolbar, { Separator } from "draft-js-static-toolbar-plugin";
// import createInlineToolbar, { Separator } from "draft-js-inline-toolbar-plugin";

// styles
import {
  Title,
  Banner,
  Paragraph,
  BannerTitle,
  OrdererList,
  EditorWrapper,
  UnorderedList,
  ShortDescription,
  CenteredContainer,
  StaticToolbarWrapper,
  WMinistryLink,
  CeneteredParagraph,
} from "./styles";

// CSS Package
import "draft-js/dist/Draft.css";
import "draft-js-alignment-plugin/lib/plugin.css";
import "draft-js-side-toolbar-plugin/lib/plugin.css";
import "draft-js-static-toolbar-plugin/lib/plugin.css";
import "draft-js-anchor-plugin/lib/plugin.css";
// import "draft-js-inline-toolbar-plugin/lib/plugin.css";

// toolbar
import {
  BoldButton,
  TitleButton,
  ItalicButton,
  AddImageButton,
  AddVideoButton,
  UnderLineButton,
  ListOrderedButton,
  ListUnorderedButton,
  ShortDescriptionButton,
  BannerButton,
  CarouselButton,
  LinkButton,
  CenterParagraphButton,
} from "./toolbar/buttons";

// ---
import DevTools from "../Devtools";
import { findWithRegex } from "./utils";
import { useEditorState } from "./store";
import { DevToolButton } from "../Devtools/styles";

// custom rendering logic
const CustomBlockRendererMap = Immutable.Map({
  "header-one": {
    element: Title,
  },
  "header-two": {
    element: ShortDescription,
  },
  "unordered-list-item": {
    element: (props) => <li {...props} />,
    wrapper: <UnorderedList />,
  },
  "ordered-list-item": {
    element: (props) => <li {...props} />,
    wrapper: <OrdererList />,
  },
  center_paragraph: {
    element: (props) => <CeneteredParagraph {...props} />,
  },
  banner: {
    element: (props) => {
      return (
        <Banner>
          <BannerTitle>{props.children}</BannerTitle>
        </Banner>
      );
    },
  },
  unstyled: {
    element: (props) => <Paragraph>{props.children}</Paragraph>,
  },
});
const extendedRenderMap = DefaultDraftBlockRenderMap.merge(
  CustomBlockRendererMap
);

const EntityDecorations = {
  decorators: [
    {
      strategy: (contentBlock, callback, contentState) => {
        contentBlock.findEntityRanges((character) => {
          const entityKey = character.getEntity();
          if (entityKey === null) {
            return false;
          }
          return contentState.getEntity(entityKey).getType() === "LINK";
        }, callback);
      },
      component: (props) => {
        const linkfyItStanitizer = linkyfyIt();
        linkfyItStanitizer.tlds(tlds);
        const { entityKey, contentState, decoratedText } = props;

        const { url } = contentState.getEntity(entityKey).getData();

        const sanitizedUrl = linkfyItStanitizer.match(url);
        const href = sanitizedUrl && sanitizedUrl[0] ? sanitizedUrl[0].url : "";

        return (
          <WMinistryLink rel="noopener noreferrer" target="_blank" href={href}>
            {decoratedText}
          </WMinistryLink>
        );
      },
    },
    {
      strategy: (contentBlock, callback) => {
        findWithRegex(/#[\w]+/gi, contentBlock, callback);
      },
      component: (props) => {
        return (
          <span
            style={{
              backgroundColor: "black",
              color: "white",
              padding: "4px",
            }}
          >
            {props.children}
          </span>
        );
      },
    },
  ],
};

// Plugins Initializations
const SideToolbar = createSideToolbar();
const FocusPlugin = createFocusPlugin();
const AnchorPlugin = createAnchorPlugin({
  Link: (props) => {
    return <WMinistryLink {...props} />;
  },
});
const StaticToolbar = createStaticToolbar();
// const InlineToolbarPlugin = createInlineToolbar();
const ResizablePlugin = createResizablePlugin();
const linkifyPlugin = createLinkfyPlugin({
  component: (props) => {
    return <WMinistryLink {...props} />;
  },
});

const AlignmentPlugin = createAlignmentPlugin();
const UndoPlugin = createUndoPlugin();

const DecoratorsForImagePlugin = composeDecorators(
  ResizablePlugin.decorator,
  FocusPlugin.decorator,
  AlignmentPlugin.decorator
);

const ImagePlugin = createImagePlugin({
  decorator: DecoratorsForImagePlugin,
  // Also can take `imageComponent` but need to handle alignment popup and alignment styles too.
  theme: {
    image: "image",
  },
});

const VideoPlugin = createVideoPlugin({
  theme: {
    iframeContainer: "",
    iframe: "video",
  },
});

const { Toolbar } = StaticToolbar;
const { AlignmentTool } = AlignmentPlugin;
const { SideToolbar: ToolbarSide } = SideToolbar;
const { addImage } = ImagePlugin;
// const { LinkButton } = AnchorPlugin;
const { addVideo } = VideoPlugin;
// const { InlineToolbar } = InlineToolbarPlugin;

const plugins = [
  EntityDecorations,
  VideoPlugin,
  SideToolbar,
  ImagePlugin,
  FocusPlugin,
  AnchorPlugin,
  AlignmentPlugin,
  UndoPlugin,
  StaticToolbar,
  // InlineToolbarPlugin,
  linkifyPlugin,
  ResizablePlugin,
];

// Buttons Map Separation for separator;
// Order Matters
const InlineStyleButtons = [
  BoldButton,
  ItalicButton,
  UnderLineButton,
  Separator,
];

const BlockButtons = [
  TitleButton,
  ShortDescriptionButton,
  BannerButton,
  CarouselButton,
  CenterParagraphButton,
  Separator,
  ListUnorderedButton,
  ListOrderedButton,
  Separator,
  AddImageButton,
  AddVideoButton,
  LinkButton,
];

// Modifiers Props for the add video and and add image buttons
const AtomicModifiers = {
  addImage,
  addVideo,
};

const RichTextEditor = () => {
  const { isDev, isReadOnly, editorState, setEditorState } = useEditorState();
  return (
    <>
      <CenteredContainer>
        {isDev && (
          <DevTools>
            <DevToolButton
              onClick={() => {
                console.log(convertToRaw(editorState.getCurrentContent()));
              }}
            >
              Log Content State
            </DevToolButton>
          </DevTools>
        )}
        <EditorWrapper>
          {!isReadOnly && (
            <StaticToolbarWrapper>
              <Toolbar>
                {(toolbarProps) => {
                  return (
                    <>
                      {InlineStyleButtons.map((Button, idx) => (
                        <Button
                          key={`inline-button-${idx}`}
                          {...toolbarProps}
                          {...AtomicModifiers}
                        />
                      ))}

                      {BlockButtons.map((Button, idx) => (
                        <Button
                          key={`block-button-${idx}`}
                          {...toolbarProps}
                          {...AtomicModifiers}
                        />
                      ))}
                    </>
                  );
                }}
              </Toolbar>
            </StaticToolbarWrapper>
          )}

          <Editor
            plugins={plugins}
            readOnly={isReadOnly}
            onChange={setEditorState}
            editorState={editorState}
            placeholder="Tell a story..."
            blockRenderMap={extendedRenderMap}
          />
          {!isReadOnly && (
            <ToolbarSide>
              {(toolbarProps) => {
                return (
                  <>
                    {[...InlineStyleButtons, ...BlockButtons].map(
                      (Button, idx) => {
                        if (Button === Separator) return null;
                        return (
                          <>
                            <Button
                              key={idx}
                              {...toolbarProps}
                              {...AtomicModifiers}
                            />
                          </>
                        );
                      }
                    )}
                  </>
                );
              }}
            </ToolbarSide>
          )}
        </EditorWrapper>
        <AlignmentTool />
        {/* <InlineToolbar>
          {(props) => {
            return (
              <>
                {InlineStyleButtons.map((Button, idx) => (
                  <Button {...props} key={`inline-toolbar-${idx}`} />
                ))}
              </>
            );
          }}
        </InlineToolbar> */}
      </CenteredContainer>
    </>
  );
};

export default RichTextEditor;
