import React from "react";
import { RichUtils } from "draft-js";
import clsx from "clsx";
import { CustomTooltip } from "../../styles";

const createInlineStyleButton = ({ style, children, label }) => {
  const InlineStyleButton = (props) => {
    const toggleStyle = (event) => {
      event.preventDefault();
      props.setEditorState(
        RichUtils.toggleInlineStyle(props.getEditorState(), style)
      );
    };

    const preventBubblingUp = (event) => {
      event.preventDefault();
    };

    // we check if this.props.getEditorstate is undefined first in case the button is rendered before the editor
    const styleIsActive = () =>
      props.getEditorState &&
      props.getEditorState().getCurrentInlineStyle().has(style);

    const { theme } = props;
    const className = styleIsActive()
      ? clsx(theme.button, theme.active)
      : theme.button;

    return (
      <CustomTooltip label={label ?? style} aria-label={label ?? style}>
        <div className={theme.buttonWrapper} onMouseDown={preventBubblingUp}>
          <button
            className={className}
            onClick={toggleStyle}
            type="button"
            children={children}
          />
        </div>
      </CustomTooltip>
    );
  };

  return InlineStyleButton;
};

export default createInlineStyleButton;
