// Inline Types
const INLINE_BOLD_TYPE = "BOLD";
const INLINE_ITALIC_TYPE = "ITALIC";
const INLINE_UNDERLINE_TYPE = "UNDERLINE";

// Block Types
const BANNER_BLOCK = "banner";
const TITLE_BLOCK = "header-one";
const SHORTDESCRIPTION_BLOCK = "header-two";

export {
  TITLE_BLOCK,
  BANNER_BLOCK,
  INLINE_BOLD_TYPE,
  INLINE_ITALIC_TYPE,
  INLINE_UNDERLINE_TYPE,
  SHORTDESCRIPTION_BLOCK,
};
