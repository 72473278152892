import React from "react";

import "./styles/index.css";
import "./styles/image.css";
import "./styles/draft-custom.css";
import "@reach/tooltip/styles.css";

// components
import RichTextEditor from "./components/Editor";
import { EditorContextProvider } from "./components/Editor/store";

function App() {
  return (
    <EditorContextProvider isDev>
      <RichTextEditor />
    </EditorContextProvider>
  );
}

export default App;
