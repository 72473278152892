import styled from "styled-components";

// Custom Block Render Styles
const Title = styled.h1`
  font-size: 50px;
  font-weight: 300;
  text-transform: capitalize;
  font-family: "Sweet Sans Pro";
`;

const Paragraph = styled.p`
  font-size: 18px;
  font-weight: 300;
  margin: 20px 0px;
  line-height: 40px;
  width: 100%;
  display: block;
`;

const UnorderedList = styled.ul`
  font-size: 18px;
  line-height: 35px;
  list-style: none inside none;

  > li {
    display: flex;
    align-items: center;
  }

  > li::before {
    content: "•";
    margin-right: 5px;
  }
`;

const OrdererList = styled.ol`
  font-size: 18px;
  line-height: 35px;
  list-style: disc inside none;
  counter-reset: order-list-counter;
  > li {
    display: flex;
    align-items: center;
    counter-increment: order-list-counter;
  }
  > li::before {
    content: counter(order-list-counter) ".";
    margin-right: 5px;
  }
`;

const ShortDescription = styled.h2`
  font-size: 15px;
  color: #b1b1b1;
  max-width: 616px;
  font-weight: 300;
  line-height: 1.375;
  margin: 20px 0px 60px 0px;
`;

const Banner = styled.p`
  width: 822px;
  height: 514px;
  display: grid;
  user-select: none;
  place-content: center;
  background-color: #1d1d1d;
`;
const BannerTitle = styled.h3`
  color: white;
  font-size: 59px;
  font-weight: 300;
  user-select: none;
  font-family: "Sweet Sans Pro";
`;

const WMinistryLink = styled.a`
  font-family: "Sweet Sans Pro";
  font-weight: bold;
  text-decoration: underline;
  color: black;
  font-style: italic;
`;

const CeneteredParagraph = styled(Paragraph)`
  text-align: end;
  outline: 1px dotted black;
`;

// Custom Wrappers
const EditorWrapper = styled.div`
  width: 1050px;
  padding: 20px;
  font-weight: 300;
  min-height: 95vh;
  margin: 10px 0px;
  max-width: 1050px;
  font-family: "Sweet Sans Pro";
  /* 
  border-radius: 12px;
  border: 1px solid gainsboro;
  box-shadow: 0px 0px 52px 5px rgba(0, 0, 0, 0.21);
  -moz-box-shadow: 0px 0px 52px 5px rgba(0, 0, 0, 0.21);
  -webkit-box-shadow: 0px 0px 52px 5px rgba(0, 0, 0, 0.21); */
`;
const StaticToolbarWrapper = styled.div`
  top: 0px;
  z-index: 99;
  position: sticky;
  background-color: white;
  display: grid;
  place-content: center;
  margin: 0px 0px 15px 0px;
`;

export {
  Title,
  Banner,
  Paragraph,
  BannerTitle,
  UnorderedList,
  EditorWrapper,
  ShortDescription,
  StaticToolbarWrapper,
  OrdererList,
  WMinistryLink,
  CeneteredParagraph,
};
