import React from "react";
// packages
import { FaBold, FaItalic, FaUnderline } from "../../styles/icons";
import { createInlineStyleButton } from "../../utils/buttons";

const BoldButton = createInlineStyleButton({
  style: "BOLD",
  children: <FaBold />,
  label: "Make Bold",
});
const ItalicButton = createInlineStyleButton({
  style: "ITALIC",
  children: <FaItalic />,
  label: "Make Italic",
});

const UnderLineButton = createInlineStyleButton({
  style: "UNDERLINE",
  children: <FaUnderline />,
  label: "Underline Selection",
});

export { BoldButton, ItalicButton, UnderLineButton };
