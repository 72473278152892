import clsx from "clsx";
import React from "react";
import { RichUtils } from "draft-js";
import { CustomTooltip } from "../../styles";

const createBlockStyleButton = ({ blockType, children, label }) => {
  const BlockStyleButton = (props) => {
    const toggleStyle = (event) => {
      event.preventDefault();
      props.setEditorState(
        RichUtils.toggleBlockType(props.getEditorState(), blockType)
      );
    };

    const preventBubblingUp = (event) => {
      event.preventDefault();
    };

    const blockTypeIsActive = () => {
      // if the button is rendered before the editor
      if (!props.getEditorState) {
        return false;
      }

      const editorState = props.getEditorState();
      const type = editorState
        .getCurrentContent()
        .getBlockForKey(editorState.getSelection().getStartKey())
        .getType();
      return type === blockType;
    };

    const { theme } = props;
    const className = blockTypeIsActive()
      ? clsx(theme.button, theme.active)
      : theme.button;

    return (
      <CustomTooltip label={label ?? blockType} aria-label={label ?? blockType}>
        <div className={theme.buttonWrapper} onMouseDown={preventBubblingUp}>
          <button
            className={className}
            onClick={toggleStyle}
            type="button"
            children={children}
          />
        </div>
      </CustomTooltip>
    );
  };

  return BlockStyleButton;
};

export default createBlockStyleButton;
