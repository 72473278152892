import React from "react";
import { FcAddImage, FcVideoFile } from "../../styles/icons";
import { createAtomicInputButton } from "../../utils/buttons";

const AddImageButton = createAtomicInputButton({
  type: "IMAGE",
  label: "Add Image",
  children: <FcAddImage />,
});
const AddVideoButton = createAtomicInputButton({
  type: "VIDEO",
  label: "Add Video",
  children: <FcVideoFile />,
});

export { AddImageButton, AddVideoButton };
