import React from "react";

// packages
import { createBlockStyleButton } from "../../utils/buttons";

// icons
import {
  AiOutlineOrderedList,
  AiOutlineUnorderedList,
  BiCarousel,
  HiTemplate,
  MdFormatSize,
  MdTitle,
  BiHorizontalCenter,
} from "../../styles/icons";

// constants
import {
  TITLE_BLOCK,
  BANNER_BLOCK,
  SHORTDESCRIPTION_BLOCK,
} from "../constants";

const TitleButton = createBlockStyleButton({
  blockType: TITLE_BLOCK,
  children: <MdTitle />,
  label: "Make Title",
});
const ShortDescriptionButton = createBlockStyleButton({
  children: <MdFormatSize />,
  blockType: SHORTDESCRIPTION_BLOCK,
  label: "Make Short Description",
});
const BannerButton = createBlockStyleButton({
  blockType: BANNER_BLOCK,
  children: <HiTemplate />,
  label: "Make Banner",
});

const ListUnorderedButton = createBlockStyleButton({
  children: <AiOutlineUnorderedList />,
  blockType: "unordered-list-item",
  label: "Make List",
});

const ListOrderedButton = createBlockStyleButton({
  children: <AiOutlineOrderedList />,
  blockType: "ordered-list-item",
  label: "Make Ordered list",
});

const CarouselButton = createBlockStyleButton({
  children: <BiCarousel />,
  blockType: "--",
  label: "Add Carousel",
});

const CenterParagraphButton = createBlockStyleButton({
  children: <BiHorizontalCenter />,
  blockType: "center_paragraph",
  label: "Center Paragraph",
});

export {
  TitleButton,
  BannerButton,
  ListUnorderedButton,
  ShortDescriptionButton,
  ListOrderedButton,
  CarouselButton,
  CenterParagraphButton,
};
