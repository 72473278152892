import {
  genKey,
  RichUtils,
  EditorState,
  ContentBlock,
  ContentState,
} from "draft-js";
import Immutable from "immutable";

/**
 *
 *
 * @link{https://stackoverflow.com/questions/49453635/draftjs-reset-blocktype-after-return}
 */
function createNewUnstyled(editorState) {
  const newBlock = ContentBlock({
    key: genKey(),
    type: "unstyled",
    text: "",
    characterList: Immutable.List(),
  });

  const contentState = editorState.getCurrentContent();

  return EditorState.push(editorState, contentState, "split-block");

  // return EditorState.push(
  //   editorState,
  //   ContentState.createFromBlockArray(newBlockMap.toArray()).set(
  //     "selectionAfter",
  //     contentState.getSelectionAfter().merge({
  //       anchorKey: newBlock.key,
  //       anchorOffset: 0,
  //       focusKey: newBlock.key,
  //       focusOffset: 0,
  //       isBackward: false,
  //     })
  //   ),
  //   "split-block"
  // );
}

// get current block key ( start block of selection )
function getCurrentBlock(editorState) {
  const currentSelection = editorState.getSelection();
  const blockKey = currentSelection.getStartKey();

  return editorState.getCurrentContent().getBlockForKey(blockKey);
}

// find with regex
function findWithRegex(regex, contentBlock, callback) {
  const text = contentBlock.getText();
  let matchArr, start;

  while ((matchArr = regex.exec(text)) !== null) {
    start = matchArr.index;
    callback(start, start + matchArr[0].length);
  }
}

// toggling block types
const toggleBlockType = (blockType, editorState, setEditorState) => {
  setEditorState(RichUtils.toggleBlockType(editorState, blockType));
};
const toggleInlineStyle = (inlineStyle, editorState, setEditorState) => {
  setEditorState(RichUtils.toggleInlineStyle(editorState, inlineStyle));
};

export {
  findWithRegex,
  getCurrentBlock,
  toggleBlockType,
  toggleInlineStyle,
  createNewUnstyled,
};

// export
export {
  createBlockStyleButton,
  createInlineStyleButton,
  createBlockAlignmentButton,
  createEntityButton,
  createAtomicInputButton,
} from "./buttons";

export { createEntity } from "./entity";
