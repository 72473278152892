import React from "react";

import Portal from "@reach/portal";
import { DevToolsContainer, DevToolButton, DevToolButtonGroup } from "./styles";
import { useEditorState } from "../Editor/store";

const DevTools = ({ children }) => {
  let { toggleReadOnly, isReadOnly } = useEditorState();

  if (!toggleReadOnly) toggleReadOnly = () => {};

  return (
    <Portal>
      <DevToolsContainer>
        <DevToolButtonGroup>
          <DevToolButton onClick={toggleReadOnly}>
            {isReadOnly ? "Enable Editable" : "Enable ReadOnly"}
          </DevToolButton>
          {children}
        </DevToolButtonGroup>
      </DevToolsContainer>
    </Portal>
  );
};

export { DevTools };

export default DevTools;
