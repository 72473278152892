import styled from "styled-components";
import Tooltip from "@reach/tooltip";

const CenteredContainer = styled.main`
  width: 100vm;
  display: grid;
  place-content: center;
`;

const CustomTooltip = styled(Tooltip)`
  background: hsla(0, 0%, 0%, 0.75);
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.5em 1em;
  font-family: "Sweet Sans Pro";
`;

const DecoratedImage = styled.img``;

const ImageWrapper = styled.div`
  width: 100%;
  padding: 10px;
  outline: 2px dotted black;
`;

export { CenteredContainer, CustomTooltip, DecoratedImage, ImageWrapper };
