import { useState, useEffect } from "react";

import { EditorState, convertFromRaw, convertToRaw } from "draft-js";

const useLocalEditorState = () => {
  const [editorState, setEditorState] = useState(() => {
    try {
      let localStorageEditorState = window.localStorage.getItem(
        "__editor_state__"
      );

      if (!localStorageEditorState) throw Error("Empty LocalStorage");
      const _editorState = JSON.parse(localStorageEditorState);
      return EditorState.createWithContent(convertFromRaw(_editorState));
    } catch (err) {
      console.info(err);
      return EditorState.createEmpty();
    }
  });

  // write to localstorage on every change.
  useEffect(() => {
    window.localStorage.setItem(
      "__editor_state__",
      JSON.stringify(convertToRaw(editorState.getCurrentContent()))
    );
  }, [editorState]);

  return [editorState, setEditorState];
};

export { useLocalEditorState };
