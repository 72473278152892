import React, { useState } from "react";
import { useLocalEditorState } from "../use/useLocalEditorState";
// import { EditorState } from "draft-js";

const EditorContext = React.createContext(null);

const EditorContextProvider = (props) => {
  // let [editorState, setEditorState] = React.useState(EditorState.createEmpty());
  const [editorState, setEditorState] = useLocalEditorState();

  const [isReadOnly, setReadOnly] = useState(props?.isReadOnly ?? false);

  const toggleReadOnly = () => setReadOnly(!isReadOnly);

  const isDev = props?.isDev ?? false;

  return (
    <EditorContext.Provider
      value={{
        isDev,
        isReadOnly,
        editorState,
        setEditorState,
        toggleReadOnly,
      }}
    >
      {props.children}
    </EditorContext.Provider>
  );
};

const useEditorState = () => React.useContext(EditorContext);

export { EditorContextProvider, useEditorState };
