import React from "react";

import clsx from "clsx";
import { CustomTooltip } from "../../styles";

import { RichUtils, EditorState } from "draft-js";

import EditorUtils from "draft-js-plugins-utils";
// getter -> alert -> url
//

const createEntity = ({
  editorState,
  type,
  mutability = "IMMUTABLE",
  data,
}) => {
  const contentState = editorState.getCurrentContent();

  const contentStateWithEntity = contentState.createEntity(
    type,
    mutability,
    data
  );

  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

  const newEditorState = EditorState.set(editorState, {
    currentContent: contentStateWithEntity,
  });

  return RichUtils.toggleLink(
    newEditorState,
    newEditorState.getSelection(),
    entityKey
  );
};

const createEntityButton = ({
  type,
  entityDataFn,
  mutability,
  label,
  children,
}) => {
  const EntityButton = (props) => {
    const handleClick = () => {
      const { setEditorState, getEditorState } = props;
      const editorState = getEditorState();

      const isLinkEntityCurrentlySelected = EditorUtils.hasEntity(
        editorState,
        "LINK"
      );

      // check if active

      if (isLinkEntityCurrentlySelected) {
        setEditorState(EditorUtils.removeLinkAtSelection(editorState));
        return;
      }

      // on if not active
      const data = entityDataFn();

      if (!data) return;
      setEditorState(EditorUtils.createLinkAtSelection(editorState, data));
    };
    const preventBubblingUp = (event) => {
      event.preventDefault();
    };

    // we check if this.props.getEditorstate is undefined first in case the button is rendered before the editor
    const styleIsActive = () => false;

    const { theme } = props;
    const className = styleIsActive()
      ? clsx(theme.button, theme.active)
      : theme.button;

    return (
      <CustomTooltip label={label ?? type} aria-label={label ?? type}>
        <div className={theme.buttonWrapper} onMouseDown={preventBubblingUp}>
          <button
            className={className}
            onClick={handleClick}
            type="button"
            children={children}
          />
        </div>
      </CustomTooltip>
    );
  };

  return EntityButton;
};

export { createEntityButton };

export default createEntityButton;
