import styled from "styled-components";

const DevToolsContainer = styled.div`
  right: 0px;
  bottom: 0px;
  padding: 50px;
  position: fixed;
  z-index: 99;
`;

const DevToolButton = styled.button`
  padding: 10px;
  display: grid;
  place-content: center;
  border: 1px solid gray;
`;

const DevToolButtonGroup = styled.div`
  gap: 5px;
  display: flex;
`;

export { DevToolsContainer, DevToolButton, DevToolButtonGroup };
