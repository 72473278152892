import React from "react";

import clsx from "clsx";

import { CustomTooltip } from "../../styles";

const handleAtomicClick = ({
  type,
  label,
  modifier,
  editorState,
  setEditorState,
}) => {
  // here is where you need to call strapi...
  const url = prompt(label ?? "Add Entity");
  if (!url || url?.trim() === "") return;

  if (!editorState) return;

  setEditorState(modifier(editorState, type === "VIDEO" ? { src: url } : url));
};

const createAtomicInputButton = ({ type, label, children }) => {
  const InlineStyleButton = (props) => {
    const preventBubblingUp = (event) => {
      event.preventDefault();
    };

    const modifier = type === "VIDEO" ? props.addVideo : props.addImage;
    const editorState = props.getEditorState ? props.getEditorState() : null;

    // we check if this.props.getEditorstate is undefined first in case the button is rendered before the editor
    const styleIsActive = () => false;

    const { theme } = props;
    const className = styleIsActive()
      ? clsx(theme.button, theme.active)
      : theme.button;

    return (
      <CustomTooltip label={label ?? type} aria-label={label ?? type}>
        <div className={theme.buttonWrapper} onMouseDown={preventBubblingUp}>
          <button
            className={className}
            onClick={() => {
              handleAtomicClick({
                type,
                modifier,
                label,
                editorState,
                setEditorState: props.setEditorState,
              });
            }}
            type="button"
            children={children}
          />
        </div>
      </CustomTooltip>
    );
  };

  return InlineStyleButton;
};

export default createAtomicInputButton;
