import React from "react";
import { createEntityButton } from "../../utils";
import { FcLink } from "../../styles/icons";

const LinkButton = createEntityButton({
  type: "LINK",
  entityDataFn: () => {
    const url = prompt("Add Url");
    if (!url || url.trim() === "") return;
    return url;
  },
  mutability: "MUTABLE",
  label: "Add Link",
  children: <FcLink />,
});

export { LinkButton };
